import React, { useState } from "react";

import { Upload, message, Button, Spin, Tooltip } from "antd";
import Styles from "./ProfileImageUpload.module.css";

import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { uploadFile } from "./api";

interface ProfileImageUploadProps {
  onImageUploaded?(filename: string): void;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  onImageUploaded
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj as File,
        (imageUrl: string | ArrayBuffer | null) => {
          setLoading(false);
          setImageUrl(imageUrl);
        }
      );
    }
  };

  const handleFileUpload = (file: File) => {
    console.log("uploading...");
    return uploadFile(file).then(filename => {
      onImageUploaded && onImageUploaded(filename);
    });
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className={Styles.Container}
      showUploadList={false}
      customRequest={({ file, onSuccess, onError }) =>
        handleFileUpload(file)
          .then(onSuccess)
          .catch(() => {
            setLoading(false);
            message.error("Upload failed :( Try again?");
            onError();
          })
      }
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <Tooltip title="Click to change">
          <img className={Styles.UploadedImage} src={imageUrl} alt="avatar" />
        </Tooltip>
      ) : (
        <div
          style={loading ? { opacity: 0.7 } : undefined}
          className={Styles.Placeholder}
        >
          {loading ? (
            <Spin
              size="large"
              style={{ margin: "auto" }}
              className={Styles.LoadingSpinner}
            />
          ) : (
            <Button type="primary" size="large" className={Styles.UploadButton}>
              Upload your image
            </Button>
          )}
        </div>
      )}
    </Upload>
  );
};

const beforeUpload = (file: any): boolean => {
  const isJPG = file.type.match(/image\//);
  if (!isJPG) {
    message.error("Not an accepted image file.");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must be smaller than 10MB.");
  }
  return isJPG && isLt2M;
};

const getBase64 = (
  img: Blob,
  callback: (res: string | ArrayBuffer | null) => void
) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default ProfileImageUpload;
