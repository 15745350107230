import { useEffect } from "react";

const useIframeOverflow = () => {
  useEffect(() => {
    const ogOverflowY = document.body.style.overflowY;
    if (window.location !== window.parent.location) {
      // The page is in an iframe
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = ogOverflowY;
    };
  }, []);
};

export default useIframeOverflow;
