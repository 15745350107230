import { CreateSubmissionResponseBody } from "./lambda/create-submission";
import { ValuesState } from "./App";
import Path from "path";
import shortid from "shortid";

interface UploadFileResponse {
  path: string;
  url: string;
}

export const uploadFile = async (file: File) => {
  const ext = Path.extname(file.name);
  const filename = shortid.generate() + ext;
  const resp = await fetch("/.netlify/functions/get-tmp-upload-link", {
    method: "post",
    body: JSON.stringify({
      filename
    }),
    headers: {
      "Content-Type": "application/json"
    }
  });
  assertStatus(resp);
  const { link }: { link: string } = await resp.json();

  await fetch(link, {
    method: "post",
    body: file,
    headers: {
      "Content-Type": "application/octet-stream"
    }
  });

  return filename;
};

// export const uploadFile = async (file: File) => {
//   const args: UploadTmpFileApiArgs = { filename: file.name };
//   const data = new FormData();
//   data.append("file", file);
//   const resp = await fetch("/.netlify/functions/upload-tmp-file", {
//     method: "post",
//     body: data,
//     headers: {
//       "API-Args": JSON.stringify(args)
//     }
//   });
//   assertStatus(resp);

//   const j: UploadFileResponseBody = await resp.json();
//   const { path, url } = j;
//   if (!path || !url) {
//     throw new Error(
//       `Expected 'path' and 'url' in response. Not found. Body was ${j}.`
//     );
//   }

//   return { path, url };
// };

export const createSubmission = async (fields: ValuesState) => {
  const resp = await fetch("/.netlify/functions/create-submission", {
    method: "post",
    body: JSON.stringify({ fields }),
    headers: {
      "Content-Type": "application/json"
    }
  });
  assertStatus(resp);

  const j: CreateSubmissionResponseBody = await resp.json();
  const { fileRequestUrl, paperUrl } = j;
  if (!fileRequestUrl || !paperUrl) {
    throw new Error(
      `Expected 'fileRequestUrl' and 'paperUrl' in response. Not found. Body was ${j}.`
    );
  }

  return { fileRequestUrl, paperUrl };
};

const assertStatus = (resp: Response) => {
  if (resp.status !== 201 && resp.status !== 200) {
    throw new Error(`Received response ${resp.status}, expected a 201 or 200.`);
  }
};

// Submissions: e.1gg8YzoPEhbTkrhvQwJ2zzy18a6fQiGDWPaOfHZiSSl4HePJ9Jbb
// Ancient Astro: id:7ZDqIkWmhHAAAAAAAAAAG
