import React, { useState } from "react";

import { Moment, isMoment } from "moment";

import { Input, DatePicker, Form, Button } from "antd";

import Styles from "./App.module.css";

import ProfileImageUpload from "./ProfileImageUpload";
import dropboxPaperPurple from "./dropbox-paper-purple.png";
import { createSubmission } from "./api";
import useIframeOverflow from "./useIframeOverflow";
import useSetDocumentDomain from "./useSetDocumentDomain";

const { TextArea } = Input;

export interface ValuesState {
  artistName: string;
  email: string;
  homeCity: string;
  story: string;
  websiteUrl: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  soundcloud: string;
  spotify: string;
  pastShow1Date?: Moment;
  pastShow1Venue: string;
  pastShow2Date?: Moment;
  pastShow2Venue: string;
  pastShow3Date?: Moment;
  pastShow3Venue: string;
  upcomingShow1Date?: Moment;
  upcomingShow1Venue: string;
  upcomingShow2Date?: Moment;
  upcomingShow2Venue: string;
  upcomingShow3Date?: Moment;
  upcomingShow3Venue: string;
  pressLink1: string;
  pressLink2: string;
  pressLink3: string;
  [k: string]: string | Moment | undefined;
  profImageFilename?: string;
}

interface ErrorsState {
  [k: string]: string;
}

const initialValuesState: ValuesState = {
  artistName: "",
  email: "",
  homeCity: "",
  story: "",
  websiteUrl: "",
  facebook: "",
  twitter: "",
  instagram: "",
  youtube: "",
  soundcloud: "",
  spotify: "",
  pastShow1Date: undefined,
  pastShow1Venue: "",
  pastShow2Date: undefined,
  pastShow2Venue: "",
  pastShow3Date: undefined,
  pastShow3Venue: "",
  upcomingShow1Date: undefined,
  upcomingShow1Venue: "",
  upcomingShow2Date: undefined,
  upcomingShow2Venue: "",
  upcomingShow3Date: undefined,
  upcomingShow3Venue: "",
  pressLink1: "",
  pressLink2: "",
  pressLink3: ""
};
const initialErrorsState: ErrorsState = {};

const STEPS = {
  SHOW_FORM: "SHOW_FORM",
  UPLOAD_REQUEST: "UPLOAD_REQUEST",
  DONE: "DONE"
};

const App: React.FC = () => {
  const [values, setValues] = useState(initialValuesState);
  const [errors, setErrors] = useState(initialErrorsState);
  const [formError, setFormError] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileRequestUrl, setFileRequestUrl] = useState();
  const [step, setStep] = useState(STEPS.SHOW_FORM);

  useIframeOverflow();
  useSetDocumentDomain();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!validateFields()) {
      setIsSubmitting(false);
      setFormError("Correct the errors in the form.");
      return;
    }

    try {
      const formattedValues = Object.keys(values).reduce(
        (memo, key) => {
          const value = values[key];
          if (isMoment(value)) {
            memo[key] = value.format("MMMM Do YYYY");
          } else {
            memo[key] = value;
          }
          return memo;
        },
        {} as ValuesState
      );
      const { fileRequestUrl, paperUrl } = await createSubmission(
        formattedValues
      );
      console.log(fileRequestUrl);
      console.log(paperUrl);
      if (window.parent) {
        try {
          window.parent.scrollTo(0, 0);
        } catch (e) {
          console.log(e);
        }
      }
      setFileRequestUrl(fileRequestUrl);
      // setPaperUrl(paperUrl);
      setStep(STEPS.UPLOAD_REQUEST);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      setFormError("Something went wrong :( Try again?");
      throw e;
    }
  };

  const validateFields = (): boolean => {
    const errors: ErrorsState = {};
    ["artistName", "email", "homeCity", "story"].forEach(fieldName => {
      const value = values[fieldName];
      if (!value) {
        errors[fieldName] = "cannot be blank";
      }
    });
    const { email } = values;
    if (email && typeof email === "string" && !email.match(/^\S+@\S+$/)) {
      errors.email = "does not appear to be valid";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const setFieldValue = (name: string, val: string | Moment) => {
    setValues(prevState => {
      return {
        ...prevState,
        [name]: val
      };
    });
  };

  const onChangeForInput = (name: string) => (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
  ) => {
    setFieldValue(name, e.currentTarget.value);
  };
  const onChangeForDatePicker = (name: string) => (value: Moment) => {
    setFieldValue(name, value);
  };

  // if (step === STEPS.DONE) {
  //   return (
  //     <div className={Styles.App}>
  //       <div className={Styles.ConfirmationPage}>
  //         <h1 className="bounce-top">🎉 Submitted</h1>
  //         <p>View your submission here at any time:</p>
  //         <p>
  //           <a href={paperUrl} target="_blank">
  //             <Icon type="select" /> {paperUrl}
  //           </a>
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (step === STEPS.UPLOAD_REQUEST) {
    return (
      <div className={Styles.FileRequestCTAContainer}>
        <div className={Styles.FileRequestCTA}>
          <a href={fileRequestUrl} target="_blank">
            <button>Upload media files</button>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.App}>
        <Form className={Styles.Form} onSubmit={onSubmit}>
          <div className={Styles.FormSection}>
            <div className={Styles.Section1Desc}>
              <p>
                The info you provide will be turned into an EPK on{" "}
                <img
                  style={{ width: "175px", marginTop: "-3px" }}
                  src={dropboxPaperPurple}
                />{" "}
                and submitted to School Night.
              </p>
              <p>
                We'll also send you a link you can use to let people know about
                you
                <em>!</em>
              </p>
            </div>
            <div className={Styles.UploadArtistImage}>
              <ProfileImageUpload
                onImageUploaded={filename =>
                  setFieldValue("profImageFilename", filename)
                }
              />
            </div>
          </div>
          <div className={Styles.FormSectionOne}>
            <div className={Styles.SectionHeader}>
              <h3>Basic Info</h3>
            </div>
            <FormInput
              placeholder="Artist Name"
              onChange={onChangeForInput("artistName")}
              value={values.artistName}
              error={errors.artistName}
              required={true}
            />
            <FormInput
              placeholder="Email Address"
              onChange={onChangeForInput("email")}
              value={values.email}
              error={errors.email}
              required={true}
            />
            <FormInput
              placeholder="Home City"
              onChange={onChangeForInput("homeCity")}
              value={values.homeCity}
              error={errors.homeCity}
              required={true}
            />
            <div className={Styles.SectionHeader}>
              <h3>Tell us your story...</h3>
            </div>
            <Form.Item
              validateStatus={errors.story ? "error" : undefined}
              help={errors.story ? errors.story : undefined}
            >
              <div style={{ position: "relative" }}>
                <TextArea
                  rows={6}
                  onChange={onChangeForInput("story")}
                  value={values.story}
                  placeholder={STORY_PLACEHOLDER}
                />
                <RequiredAsterisk
                  style={{ position: "absolute", right: "11px", top: "20px" }}
                />
              </div>
            </Form.Item>
            <div className={Styles.SectionHeader}>
              <h3>Profiles</h3>
            </div>
            <FormInput
              placeholder="Website URL"
              onChange={onChangeForInput("websiteUrl")}
              value={values.websiteUrl}
            />
            <FormInput
              addonBefore="facebook.com/"
              onChange={onChangeForInput("facebook")}
              value={values.facebook}
            />
            <FormInput
              addonBefore="twitter.com/"
              onChange={onChangeForInput("twitter")}
              value={values.twitter}
            />
            <FormInput
              addonBefore="instagram.com/"
              onChange={onChangeForInput("instagram")}
              value={values.instagram}
            />
            <FormInput
              addonBefore="youtube.com/"
              onChange={onChangeForInput("youtube")}
              value={values.youtube}
            />
            <FormInput
              addonBefore="soundcloud.com/"
              onChange={onChangeForInput("soundcloud")}
              value={values.soundcloud}
            />
            <FormInput
              placeholder="Spotify URL"
              onChange={onChangeForInput("spotify")}
              value={values.spotify}
            />
          </div>
          <div className={Styles.FormSectionTwo}>
            <div className={Styles.SectionHeader}>
              <h3 style={{ height: "25px" }}>Past Shows</h3>
            </div>
            <Show
              onDateChange={onChangeForDatePicker("pastShow1Date")}
              onVenueChange={onChangeForInput("pastShow1Venue")}
              date={values.pastShow1Date}
              venue={values.pastShow1Venue}
            />
            <Show
              onDateChange={onChangeForDatePicker("pastShow2Date")}
              onVenueChange={onChangeForInput("pastShow2Venue")}
              date={values.pastShow2Date}
              venue={values.pastShow2Venue}
            />
            <Show
              onDateChange={onChangeForDatePicker("pastShow3Date")}
              onVenueChange={onChangeForInput("pastShow3Venue")}
              date={values.pastShow3Date}
              venue={values.pastShow3Venue}
            />
            <div className={Styles.SectionHeader}>
              <h3>Upcoming Shows</h3>
            </div>
            <Show
              onDateChange={onChangeForDatePicker("upcomingShow1Date")}
              onVenueChange={onChangeForInput("upcomingShow1Venue")}
              date={values.upcomingShow1Date}
              venue={values.upcomingShow1Venue}
            />
            <Show
              onDateChange={onChangeForDatePicker("upcomingShow2Date")}
              onVenueChange={onChangeForInput("upcomingShow2Venue")}
              date={values.upcomingShow2Date}
              venue={values.upcomingShow2Venue}
            />
            <Show
              onDateChange={onChangeForDatePicker("upcomingShow3Date")}
              onVenueChange={onChangeForInput("upcomingShow3Venue")}
              date={values.upcomingShow3Date}
              venue={values.upcomingShow3Venue}
            />
            <div className={Styles.SectionHeader}>
              <h3>Press Links</h3>
            </div>
            <Input
              onChange={onChangeForInput("pressLink1")}
              value={values.pressLink1}
              placeholder="URL"
            />
            <Input
              onChange={onChangeForInput("pressLink2")}
              value={values.pressLink2}
              placeholder="URL"
            />
            <Input
              onChange={onChangeForInput("pressLink3")}
              value={values.pressLink3}
              placeholder="URL"
            />
            <p style={{ fontSize: "12px", margin: 0, textAlign: "right" }}>
              <span style={{ color: "#f5222d" }}>*</span> indicates a required
              field
            </p>
            <p style={{ margin: "0px" }}>
              You will submit music and any other media in the next step.
            </p>
            <div className={Styles.Submit}>
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                {isSubmitting ? "Submitting..." : "Next"}
              </Button>
              <p>
                By clicking "Next", you are confirming that you are authorized
                to submit these materials on behalf of the artist.
              </p>
            </div>
            <p className={Styles.FormError}>{formError}</p>
          </div>
        </Form>
        <div className={Styles.Footer}>
          <p>
            Powered by{" "}
            <img
              style={{ width: "150px", marginTop: "-3px" }}
              src={dropboxPaperPurple}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

interface ShowProps {
  onDateChange(value: Moment): void;
  onVenueChange(e: React.FormEvent<HTMLInputElement>): void;
  date?: Moment;
  venue: string;
}

const Show = ({ onDateChange, onVenueChange, date, venue }: ShowProps) => (
  <div className={Styles.ShowField}>
    <DatePicker onChange={onDateChange} value={date} />
    <Input onChange={onVenueChange} placeholder="Venue" value={venue} />
  </div>
);

interface FormInputProps {
  onChange(e: React.FormEvent<HTMLInputElement>): void;
  placeholder?: string;
  label?: string;
  value: string;
  error?: string;
  addonBefore?: string;
  required?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  onChange,
  label,
  placeholder,
  value,
  addonBefore,
  error,
  required
}) => (
  <Form.Item
    label={label}
    validateStatus={error ? "error" : undefined}
    help={error ? error : undefined}
  >
    <Input
      addonBefore={addonBefore}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      suffix={required ? <RequiredAsterisk /> : null}
    />
  </Form.Item>
);

const RequiredAsterisk = ({ style }: { style?: React.CSSProperties }) => (
  <span style={style} className={Styles.RequiredAsterisk}>
    *
  </span>
);

interface Show {
  date: string;
  venue: string;
}

const STORY_PLACEHOLDER = `What makes you stand out, what's your show history, what's your proudest moment to date, and what's up next. This is the best opportunity to make your case (so don't just copy and paste).`;

export default App;
