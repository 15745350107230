import { useEffect } from "react";

const useSetDocumentDomain = () => {
  useEffect(() => {
    const ogDomain = document.domain;
    if (!ogDomain.match(/localhost/)) {
      try {
        document.domain = "itsaschoolnight.com";
      } catch (e) {
        console.log(e);
      }
    }
    return () => {
      document.domain = ogDomain;
    };
  }, []);
};

export default useSetDocumentDomain;
